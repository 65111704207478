import { createGetKcContext } from "keycloakify/account";

export type KcContextExtension = { pageId: "password.ftl" };

export const { getKcContext } = createGetKcContext<KcContextExtension>({
	mockData: [
	]
});

export const { kcContext } = getKcContext({
	// mockPageId: "password.ftl",
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;
